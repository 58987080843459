const ContactVariants = {
    hidden: {
        background: "#1110",
        boxShadow: "0 0 0px #0006"
    },
    visible: {
        background: "#1113",
        boxShadow: "0 0 60px #0006",
        transition: {
            when: "beforeChildren",
            delay: 1.5,
            staggerChildren: .1,
            duration: .8,
            ease: [0, 0.71, 0.2, 1.01]
        }
    }
}


export default ContactVariants;