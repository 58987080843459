const AboutVariants = {
    hidden: {
        opacity: 0,
        transform: `translateY(-20px)`,
    },
    visible: {
        opacity: .7,
        transform: `translateY(0)`
    }
};


const AboutWrapVariants = {
    hidden: {
    },
    visible: {
        transition: {
            when: "beforeChildren",
            delayChildren: 1.5,
            staggerChildren: .4,
            duration: .6,
            ease: [0, 0.71, 0.2, 1.01]
        }
    }
};



export {
    AboutVariants, AboutWrapVariants
};