import { useState } from "react";
import { Background, Content, Section, Title } from "../Section/Section";
import PortfolioList from "./PortfolioList";
import MainSlide from "./PortfolioSlide/Main";
import "../../assets/css/portfolio.scss"
import SlideControl from "./PortfolioSlide/SlideControl";



// Components
function Portfolio() {
    const [ currentSlide, slideTo ] = useState(0);
    const portfolios = [...PortfolioList];
    
    return (
        <Section id="portfolio">
            <Title 
                title="PREVIEW"
                subtitle={<span>제가 만든 것들을<br />미리 둘러보세요</span>}
                englishTitle="PORTFOLIO" />
            
            <Content className="col-flex jcc">
                <MainSlide slideIndex={currentSlide} list={portfolios}></MainSlide>
                <SlideControl slideIndex={currentSlide} slideTo={slideTo} max={portfolios.length}></SlideControl>
            </Content>

            <Background>
                <span>03</span>
            </Background>
        </Section>
    );
};



// Export
export default Portfolio;