import { motion } from "framer-motion";



// Properties

interface ProfileProps {
    imagePath: string;
}



// Components

function Profile({ imagePath }: ProfileProps) {
    return ( 
        <motion.h1 
            className="visual-profile" 
            style={( {
                backgroundImage: `url('${imagePath}')`
            } )}
            initial={{ transform: "translate(-50%, calc(-50% - 300px))", opacity: 0 }}
            animate={{ transform: "translate(-50%, -50%) translateZ(10px)", opacity: 1}}
            transition={{ duration: .6, delay: .3, ease: [0, 0.71, 0.2, 1.01] }}>
            ✦
        </motion.h1>
    );
}



// Exports

export default Profile;