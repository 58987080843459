import { motion } from "framer-motion";
import { ReactNode } from "react";



// Properties

interface InnerSpanProps {
    index: number;
    children: string | ReactNode;
    delay?: number;
    baseDelay?: number;
}



// Components

function InnerSpan({ index, children, delay = 1, baseDelay = 0.05 }: InnerSpanProps) {
    const variants = {
        "i0": { transform: "translateX(-120px)", opacity: 0 },
        "i1": { transform: "translateY(-120px)", opacity: 0 },
        "i2": { transform: "translateX( 120px)", opacity: 0 },
        "i3": { transform: "translateY( 120px)", opacity: 0 },

        "a0": { transform: "translateX(0px)", opacity: 1 },
        "a1": { transform: "translateY(0px)", opacity: 1 },
        "a2": { transform: "translateX(0px)", opacity: 1 },
        "a3": { transform: "translateY(0px)", opacity: 1 }
    };

    const transition = {
        ease: [0, 0.71, 0.2, 1.01]
    };

    return (
        <motion.span
            variants={ variants }
            initial={ "i" + (index % 4).toString() }
            animate={ "a" + (index % 4).toString() }
            transition={{ ...transition, delay: baseDelay + index * delay }}
        >
            {children}
        </motion.span>
    )

}



// Exports

export default InnerSpan;