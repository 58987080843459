import { faGithub, faInstagram } from "@fortawesome/free-brands-svg-icons";
import ContactItem from "./ContactItem";
import { faAngleUp, faEnvelope, faLocationDot, faPhone } from "@fortawesome/free-solid-svg-icons";


const ContactCardList: ContactItem[] = [
    {
        icon: faGithub,
        key: "GitHub",
        value: "@playerdecuple",
        foregroundColor: "#ff6247",
        backgroundColor: "#fff",
        href: "https://github.com/playerdecuple",
        beforeStyle: {
            transform: "translate(-100%, -100%)"
        },
        afterStyle: {
            transform: "translate(0%, 0%)"
        }
    },
    {
        icon: faPhone,
        key: "Tel",
        value: "010-2246-7197",
        foregroundColor: "#fff",
        backgroundColor: "#ff6247",
        href: "tel:01022467197",
        beforeStyle: {
            transform: "translate(0%, -100%)"
        },
        afterStyle: {
            transform: "translate(0%, 0%)"
        }
    },
    {
        icon: faInstagram,
        key: "Instagram",
        value: "@player_decuple",
        foregroundColor: "#111",
        backgroundColor: "#ff6247",
        href: "https://www.instagram.com/player_decuple/",
        beforeStyle: {
            transform: "translate(-100%, 0%)"
        },
        afterStyle: {
            transform: "translate(0%, 0%)"
        }
    },
    {
        icon: faEnvelope,
        key: "Email",
        value: "kr.decu@gmail.com",
        foregroundColor: "#fff",
        backgroundColor: "#111",
        href: "mailto:kr.decu@gmail.com",
        beforeStyle: {
            transform: "translate(100%, 0%)"
        },
        afterStyle: {
            transform: "translate(0%, 0%)"
        }
    },
    {
        icon: faEnvelope,
        key: "Email",
        value: "playerdecuple@gmail.com",
        foregroundColor: "#ff6247",
        backgroundColor: "#111",
        href: "mailto:playerdecuple@gmail.com",
        beforeStyle: {
            transform: "translate(0%, 100%)"
        },
        afterStyle: {
            transform: "translate(0%, 0%)"
        }
    },
    {
        icon: faLocationDot,
        key: "Location",
        value: "경기도 안산시 거주",
        foregroundColor: "#111",
        backgroundColor: "#fff",
        beforeStyle: {
            transform: "translate(100%, 100%)"
        },
        afterStyle: {
            transform: "translate(0%, 0%)"
        }
    },
    {
        icon: faAngleUp,
        key: "Page Top",
        value: "맨 위로",
        foregroundColor: "#fff",
        backgroundColor: "#883a2c",
        href: "#visual",
        beforeStyle: {
            transform: "rotate(45deg) scale(2)"
        },
        afterStyle: {
            transform: "rotate(0deg) scale(1)"
        }
    }
];


export default ContactCardList;