import PortfolioItem from "./PortfolioItem";


const PortfolioList: PortfolioItem[] = [
    {
        imagePath: process.env.PUBLIC_URL + "/images/previews/이한메미술관.png",
        title: "이한메미술관",
        description: "'이한메미술관'은 경상남도의 민간정원을 위해 운영되는 미술관으로, 이 사이트를 통해 사용자에게 '이한메미술관'의 일정, 대표 작품 등을 소개하고, 사용자가 '이한메미술관'의 청년 작가를 후원할 수 있도록 돕습니다. 실제 '이한메미술관'과는 큰 연관 없이 〈전국기능경기대회〉의 과제로서, 디자인, 레이아웃, 스타일링, 프론트엔드, 백엔드를 모두 스스로 제작한 페이지입니다. CSS의 3D Transform과, AJAX와 PHP를 활용한 페이지 이동 없는 로그인을 구현함으로서 이해도를 높이는 데 도움이 되었습니다.",
        tagList: ["Web Project", "HTML", "CSS", "JavaScript", "jQuery", "PHP", "Fullstack"],
        href: "/portfolios?year=2022&code=01",
        date: "약 7일 (2022. 8.)"
    },
    {
        imagePath: process.env.PUBLIC_URL + "/images/previews/P-Media.png",
        title: "P-Media",
        description: "P-Media는 본래 애니메이션 스튜디오였다가, HTML5 게임을 호스팅하는 플랫폼으로 방향을 전환하면서, 게이머들이 '게임 플랫폼 P-Media'의 정보를 웹 페이지를 통해 얻을 수 있도록 계획했습니다. 실제로는 존재하지 않는 플랫폼이지만, 〈2023 리옹 국제기능올림픽 국가대표평가전 1차전〉의 과제로서 요구사항에 따라 디자인과 레이아웃, 그리고 스타일링을 스스로 제작하였습니다. 로드맵의 애니메이션, 카드 디자인 기법과 이모지를 활용한 디자인, 그리고 반응형 디자인을 설계해볼 수 있었습니다.", 
        tagList: ["Web Project", "HTML", "CSS", "Responsive", "Frontend"],
        href: "/portfolios?year=2023&code=01",
        date: "약 2일 (2023. 5.)"
    },
    {
        imagePath: process.env.PUBLIC_URL + "/images/previews/Stars-In-Graz.png",
        title: "Stars In Graz",
        description: "'Stars In Graz'는 여러 유명 아티스트들이 참여하는 음악 콘서트입니다. 저번 해의 큰 반응에 힘입어, 이번 해에는 더욱 쉽고 빠른 예약이 가능하고, 더욱 많은 사람들에게 관심을 받기 위해 홍보 웹페이지를 계획했습니다. 실제로는 존재하지 않는 콘서트이지만, 〈2023 리옹 국제기능올림픽 국가대표평가전 2차전〉의 과제로서 요구사항에 따라 디자인과 레이아웃, 그리고 스타일링을 스스로 제작했습니다. 마케팅 페이지를 직접 만들어봄으로서 사용자들에게 정보를 제공해야 함을 고려해보았습니다.",
        tagList: ["Web Project", "HTML", "CSS", "Frontend"],
        href: "/portfolios?year=2023&code=02",
        date: "약 2일 (2023. 6.)"
    }
];


export default PortfolioList;