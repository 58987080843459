import InnerSpan from "./InnerSpan";
import Profile from "./Profile";
import Line from "./Line";



// Properties

interface InnerProps {
    decorate: InnerDecorateProps;
    profileImage: string;
    title: string;
    subtitle: string;
}

interface InnerDecorateProps {
    top: string;
    bottom: string;
};



// Components

function Inner({ decorate, profileImage, title, subtitle }: InnerProps) {

    // Visual Inner Render
    return (
        <div className="visual-inner col-flex">
            <Line content={ decorate.top } className="flex"></Line>
            <Line content={ decorate.bottom } className="flex-rev"></Line>
            
            <Profile imagePath={ profileImage }></Profile>

            <div className="visual-content col-flex aic">
                <h2>
                    { 
                        title.split('').map((v, i) => 
                            <InnerSpan key={i} index={i} baseDelay={.7} delay={0.05}>{v}</InnerSpan>
                        ) 
                    }
                </h2>
                <p>
                    { 
                        subtitle.split(' ').map((v, i) => 
                            <InnerSpan key={i} index={i} baseDelay={.7} delay={0.25}>{v}</InnerSpan>
                        ) 
                    }
                </p>
            </div>
        </div>
    );

}



// Exports

export default Inner;