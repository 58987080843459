import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

    
// Properties
interface SlideControlProps {
    slideIndex: number,
    slideTo: React.Dispatch<React.SetStateAction<number>>,
    max: number;
}


// Components
function SlideControl({slideIndex, slideTo, max }: SlideControlProps) {
    const slide = (n: number) => {
        if (n < 0) n = max - 1;
        if (n > max - 1) n = 0;
        slideTo(n);
    };

    return (
        <div className="main-slide-control flex aic">
            <div className="btns">
                <button onClick={() => slide(slideIndex - 1)}>
                    <FontAwesomeIcon icon={faAngleLeft}></FontAwesomeIcon>
                </button>
                <button onClick={() => slide(slideIndex + 1)}>
                    <FontAwesomeIcon icon={faAngleRight}></FontAwesomeIcon>
                </button>
            </div>

            <div className="circles">
                {
                    new Array(max).fill(0).map((_, i) => (
                        <button className={
                            i === slideIndex ? "main-slide-circle-active" : ""
                        } onClick={() => slide(i)} key={i}></button>
                    ))
                }
            </div>
        </div>
    )
}


// Export
export default SlideControl;