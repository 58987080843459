import { motion } from "framer-motion";
import { Background, Content, Section, Title } from "../Section/Section";
import CardList from "./Card/CardList";

import "../../assets/css/skills.scss";
import Card from "./Card/Card";
import SkillsList from "./SkillList";
import { faArrowPointer, faComputerMouse } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


// Compnents
function Skillstack() {
    const skills = [...SkillsList];
    const stretchedSkills = [] as (typeof skills);
    
    for (let i = stretchedSkills.length; i < 60; i += skills.length) {
        stretchedSkills.push(...skills);
    }

    // Render
    return (
        <Section id="skills">
            <Title 
                title="MY SKILLS" 
                subtitle={<span>저는 이런 것들을<br />할 수 있습니다</span>}
                englishTitle="SKILLSTACKS" />

            <Content>
                <CardList>
                    {
                        stretchedSkills.map( (skill, i) =>
                            <Card key={i} color={skill.color} title={skill.title} icon={skill.icon}>
                                {skill.content}
                            </Card>
                        )
                    }
                </CardList>
            </Content>

            <Background>
                <div className="guideline-anims">
                    <div className="col-flex">
                        <FontAwesomeIcon icon={faComputerMouse} />
                        <p>마우스를 스크롤하여<br />더 많은 카드를 확인하실 수 있습니다</p>
                    </div>
                    <div className="col-flex">
                        <FontAwesomeIcon icon={faArrowPointer} />
                        <p>카드를 클릭하여<br />세부 정보를 확인하실 수 있습니다</p>
                    </div>
                </div>

                <span>02</span>
            </Background>
        </Section>
    )
}


// Exports
export default Skillstack;