const CardListVariants = {
    hidden: {
        top: "200vh",
        opacity: 0
    },
    visible: {
        top: "240vh",
        opacity: 1,
        transition: {
            staggerChildren: 0,
            ease: "easeOut"
        }
    }
};



export {
    CardListVariants
};