import React, { CSSProperties, ReactNode, useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import "../../assets/css/section.scss";

import { titleVariants, titleWrapVariants } from "./Variants";
import { useInView } from "react-intersection-observer";



// Properties

interface SectionProps {
    children: ReactNode;
    className?: string;
    id?: string;
}

interface TitleProps {
    title: string | ReactNode;
    subtitle: string | ReactNode;
    englishTitle: string | ReactNode;
    icon?: string
};

interface BackgroundProps {
    children: ReactNode;
    style?: CSSProperties;
}



// Components

function Section({ children, className = "", id }: SectionProps) {
    const onMouseMove = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        const diffX = e.clientX - window.innerWidth / 2;
        const diffY = e.clientY - window.innerHeight / 2;

        e.currentTarget.style.perspectiveOrigin = `${diffX}px ${diffY}px`;
    };

    return (
        <div className={ [ "section", className ].join(" ") } id={ id } onMouseMove={ onMouseMove }>
            { children }
        </div>
    );
}


function Title({ title, subtitle, englishTitle, icon = "✦" }: TitleProps) {
    const engTitleController = useAnimation();
    const dummyController = useAnimation();
    const wrapController = useAnimation();

    const [ ref, inView ] = useInView({ triggerOnce: true });
    const transition = {
        duration: .6, 
        ease: [0, 0.71, 0.2, 1.01]
    };

    useEffect(() => {
        if (inView) {
            engTitleController.start("englishTitleShow");
            dummyController.start("dummyTitleShow");
            wrapController.start("visible");
        }
    }, [ engTitleController, dummyController, wrapController, inView ]);

    // Title Render
    return (
        <motion.div 
            className="section-title col-flex aifs" 
            ref={ ref } 
            variants={ titleWrapVariants }
            initial="hidden"
            animate={ wrapController }>
            <motion.h1 className="section-icon" variants={ titleVariants }>{ icon }</motion.h1>
            <motion.h1 variants={ titleVariants }>{ title }</motion.h1>

            <motion.h3 
                className="section-eng-title"
                variants={ titleVariants }
                initial="englishTitleHide"
                animate={ engTitleController }
                transition={{ ...transition, delay: .6 }}>
                { englishTitle }
                <motion.span 
                    className="dummy-eng-title"
                    variants={ titleVariants }
                    initial="dummyTitleHide"
                    animate={ dummyController }
                    transition={{ ...transition, delay: .8 }}>
                    { englishTitle }
                </motion.span>
            </motion.h3>

            <motion.p variants={ titleVariants }>{ subtitle }</motion.p>

            <motion.hr variants={{
                "hidden": { width: 0 },
                "visible": { width: 200 }
            }} />
        </motion.div>
    );
}


function Content({ children, className = "", id }: SectionProps) {
    return (
        <div className={ [ "section-content", className ].join(" ") } id={ id }>
            { children }
        </div>
    )
}


function Background({ children, style }: BackgroundProps) {
    return (
        <div className="section-background" style={ style }>
            {children}
        </div>
    );
}



// Exports

export {
    Section,
    Title,
    Content,
    Background
};
