import "../../assets/css/visual.scss";
import Background from "./Background";
import Inner from "./Inner";



// Components

function Visual() {
    
    const onMouseMove = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        const diffX = e.clientX - window.innerWidth / 2;
        const diffY = e.clientY - window.innerHeight / 2;

        e.currentTarget.style.perspectiveOrigin = `${diffX}px ${diffY}px`;
    };
    
    // Visual Render
    return (
        <div id="visual" onMouseMove={ onMouseMove }>
            <Background top="PLAYER" bottom="DECUPLE" />

            <Inner
                decorate={( {
                    top: "Fullstack Developer",
                    bottom: "Introduce Webpage"
                } )}
                profileImage={
                    process.env.PUBLIC_URL + "/images/profile.jpg"
                }
                title="player_decuple"
                subtitle="Kim Dae Joong"
            ></Inner>
        </div>
    );

}



// Exports

export default Visual;