import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StyleList from "../../../utilities/StyleList";
import PortfolioItem from "../PortfolioItem";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { faEye } from "@fortawesome/free-solid-svg-icons";


// Properties
interface MainSlideItemProps {
    item: PortfolioItem;
    active: boolean;
}


// Components
function MainSlideItem({item, active}: MainSlideItemProps) {
    return (
        <div className={StyleList(
            "main-slide-item", active ? "main-slide-active" : ""
        )}>
            <div className="main-slide-img" style={{
                backgroundImage: `url(${item.imagePath})`
            }}></div>
            <div className="main-slide-content">
                <div className="col-flex main-slide-top">
                    <span>{item.tagList[0]}</span>
                    <h1>{item.title}</h1>
                    <p className="main-slide-description">{item.description}</p>
                </div>

                <div className="col-flex aifs">
                    <p className="tag-list">{
                        item.tagList.slice(1, Infinity).map(tag => <span className="tag" key={tag}>{tag}</span>)
                    }</p>
                    <div className="flex main-slide-btnlist">
                        <a href={item.href} className="button">
                            <FontAwesomeIcon icon={faEye}></FontAwesomeIcon>
                            <span>미리보기</span>
                        </a>
                        {
                            item.githubHref !== undefined
                                ? <a href={item.githubHref} className="button github">
                                    <FontAwesomeIcon icon={faGithub}></FontAwesomeIcon>
                                    <span>GitHub에서 보기</span>
                                </a>
                                : <></>
                        }
                    </div>

                    <small className="main-slide-date">
                        <b>진행 기간</b> { item.date }
                    </small>
                </div>
            </div>
        </div>
    );
}   



// Export
export default MainSlideItem;