import { AnimationControls, motion, useAnimation } from "framer-motion";
import React, { ReactNode, useState } from "react";



// Properties

interface CardProps {
    color: string[];
    children: ReactNode;
    title: string;
    icon: string;
    angle?: number;
}



// Components

function Card({ color, children, title, icon, angle = 0 }: CardProps) {
    return (
        <motion.div 
            className="skill-card"
            data-angle={ angle }
            variants={{
                visible: {
                    transform: `translate(-50%, -50%) rotate(${angle}deg) translateY(-195vh)`,
                    opacity: 1,
                    transition: {
                        ease: "easeOut",
                        duration: .6
                    }
                }
            }}>
            
            <div className="card-info flex aic jcc"
                data-angle={ angle }
                data-color={ color[0] }
                style={{
                    background: `linear-gradient(-35deg, #151111 60%, ${color[0]}22)`,
                    boxShadow: `0px 0px 30px ${color[0]}22`
                }}>
                    <div className="col-flex aic jcc card-left">
                        <img src={ icon } alt="icon" />
                        <h2>{ title }</h2>
                    </div>

                    <div className="card-right">
                        { children }
                    </div>
            </div>

        </motion.div>
    );
}


export default Card;