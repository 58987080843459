import { Background, Content, Section, Title } from "../Section/Section";
import ContactCard from "./ContactCard";
import ContactCardList from "./ContactCardList";
import "../../assets/css/contact.scss"
import { motion, useAnimation } from "framer-motion";
import ContactVariants from "./Variants";
import { useInView } from "react-intersection-observer";
import { useEffect, useState } from "react";


// Components
function Contact() {    
    const [ isLoaded, setLoaded ] = useState(false);
    const [ ref, inView, entry ] = useInView({ 
        threshold: 0.5,
        triggerOnce: true 
    });
    const year = new Date().getFullYear();
    const cardController = useAnimation();

    useEffect(() => {
        if (inView && !isLoaded) {
            cardController.start("visible");
            setLoaded(true);
        }
    }, [ inView, entry ]);

    // Render
    return (
        <Section id="contact">
            <Title 
                title="CONTACT"
                subtitle={<span>연락처 및<br />문의처입니다</span>}
                englishTitle="INFORMATION" />
            
            <Content>
                <motion.div
                    className="contact-card-wrap"
                    variants={ ContactVariants }
                    ref={ref}
                    initial="hidden"
                    animate={ cardController }>
                    {
                        [...ContactCardList].map((contact, i) => (
                            <ContactCard contact={contact} key={i} />
                        ))  
                    }
                </motion.div>
            </Content>

            <Background>
                <span>04</span>
                <small className="copyright">ⓒ {year} playerdecuple, All rights reserved.</small>
            </Background>
        </Section>
    )
}


// Exports
export default Contact;