import { motion, useAnimation } from "framer-motion";
import { Background, Content, Section, Title } from "../Section/Section";
import "../../assets/css/about.scss";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import { AboutVariants, AboutWrapVariants } from "./Variants";


// Components

function About() {
    const contentController = useAnimation();
    const [ ref, inView ] = useInView({ triggerOnce: true });

    useEffect(() => {
        if (inView) {
            contentController.start("visible");
            contentController.start("visibleImage");
        }
    }, [ inView, contentController ]);

    return (
        <Section id="about">
            <Title 
                title="ABOUT ME" 
                subtitle={<span>저는 이런<br />개발자입니다</span>}
                englishTitle="INTRODUCE" />

            <Content>
                <motion.div  
                    ref={ ref }
                    className="col-flex aifs jcfe"
                    variants={ AboutWrapVariants }
                    initial="hidden"
                    animate={ contentController }>
                    <motion.p 
                        className="content"
                        variants={ AboutVariants }>
                        도전이라는 단어는 저에겐 두려운 것이 아닙니다. 도리어 제게 큰 힘을 줍니다.<br />
                        어력을 적부터 Visual Basic 6으로 자신만의 프로그램을 만들기 시작하며<br />
                        많은 도전의 결과로서, 〈전국기능경기대회〉에 나가 웹 기술 부문의 입상자가 되기도 하였습니다.<br />
                    </motion.p>
                    <motion.hr
                        variants={{
                            hidden: {
                                opacity: 0,
                                width: 0
                            },
                            visible: {
                                opacity: .2,
                                width: 200
                            }
                        }} />
                    <motion.p 
                        className="content"
                        variants={ AboutVariants }>
                        언제나 완벽함을 추구하고 문제 해결을 위해 많은 아이디어를 생각하고,<br />
                        어떻게 하면 사용자가 편리하게 사용할 수 있을지 고민하며 프로그램을 만듭니다.<br />
                        새 기술엔 언제나 관심을 갖고 모르는 것, 그리고 알고 있는 것이라도 항상 공부합니다.<br />
                    </motion.p>
                    <br />
                    <motion.p
                        className="content"
                        variants={ AboutVariants }>
                        저는 그런 풀스택 개발자인, 김대중입니다.
                    </motion.p>
                </motion.div>
            </Content>

            <Background>
                <span>01</span>
                <motion.div 
                    className="bg-img-wrap"
                    variants={{
                        hidden: {
                            opacity: 0
                        },
                        visible: {
                            opacity: 1,
                            transition: {
                                delay: 3,
                                duration: .6,
                                ease: "easeInOut"
                            }
                        }
                    }}
                    initial="hidden"
                    animate={ contentController }>
                    <img src={ process.env.PUBLIC_URL + "/images/about/1.jpg" } alt="background" title="Image" />
                    <div className="bg-img-cover"></div>
                </motion.div>
            </Background>
        </Section>
    );
};


export default About;