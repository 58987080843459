import React, { useEffect, useState } from "react";
import { Children, ReactNode } from "react";

import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { CardListVariants } from "./Variants";
import { CardEvents } from "./CardEvent";


// Properties
interface CardListProps {
    children: ReactNode;
}


// Components
function CardList({ children }: CardListProps) {
    const [ angle, setAngle ] = useState(0);
    const [ isLoaded, setLoaded ] = useState(false);

    const [ ref, inView, entry ] = useInView({ triggerOnce: true });

    const childrenArray = Children.toArray(children).filter(child => React.isValidElement(child));
    const cardList = childrenArray as React.ReactElement[];
    const sepAngle = 360 / cardList.length;

    const cardController = useAnimation();
    const cardComponents = cardList.map((card, idx) => {
        const clone = React.cloneElement(card, { 
            angle: sepAngle * idx, 
            controller: cardController
        });
        return clone;
    });

    useEffect(() => {
        const element = entry?.target;

        const wheelHandler = CardEvents.onWheel(angle, setAngle);
        const clickHandler = CardEvents.onClick(angle, setAngle);

        if (inView && !isLoaded) {
            cardController.start("visible");
            setLoaded(true);
        }

        element?.addEventListener('wheel', wheelHandler, { passive: false });
        element?.addEventListener('click', clickHandler);

        return () => {
            element?.removeEventListener('wheel', wheelHandler);
            element?.removeEventListener('click', clickHandler);
        }
    }, [ cardController, inView, angle, isLoaded, entry ]);

    // Render
    return (
        <motion.div 
            className="card-list"
            ref={ ref }
            variants={ CardListVariants }
            initial="hidden"
            animate={ cardController }
            style={{
                transform: `translate(-50%, -50%) rotate(${angle}deg)`
            }}>
            { cardComponents }
        </motion.div>
    );
}


export default CardList;