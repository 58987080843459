const CardEvents = {

    onWheel(angle: number, setAngle: React.Dispatch<React.SetStateAction<number>>) {
        return (e: Event) => {
            const ev = e as WheelEvent;
            const diffAngle = ev.deltaY / -20;

            document.querySelectorAll(".card-info").forEach(el => el.classList.remove("active"));

            setAngle(angle + diffAngle);
            e.preventDefault();
        }
    },


    onClick(angle: number, setAngle: React.Dispatch<React.SetStateAction<number>>) {
        return (e: Event) => {
            let target = e.target as Element;
            const isValidTarget = target.classList.contains("card-info") || target.classList.contains("skill-card");
            if (!target || !isValidTarget) return;
            if (target && target.classList.contains("skill-card")) {
                target = target.querySelector(".card-info")!!;
            }
            if (target.classList.contains("active")) return;

            document.querySelectorAll(".card-info").forEach(el => el.classList.remove("active"));
            target.classList.add("active");

            const cardAttr = target.getAttribute("data-angle")!! as string;
            const color = target.getAttribute("data-color")!!;
            const divAngle = angle % 360;
            let cardAngle = (Number.parseInt(cardAttr)) + divAngle;

            if (cardAngle > 180) {
                cardAngle %= 360;
                cardAngle -= 360;
            }
            if (cardAngle < -180) {
                cardAngle %= 360;
                cardAngle += 360;
            }
            // cardAngle = cardAngle > 180
            //     ? cardAngle - 360
            //     : cardAngle;

            const targetAngle = angle - cardAngle;
            setAngle(targetAngle);

            const $section = document.getElementById("skills")!!
            const $icon = $section.querySelector(".section-icon")!! as HTMLDivElement;
            $section.style.background = color + "22";
            $icon.style.color = color;
        }
    }

}



export {
    CardEvents
};