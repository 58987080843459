import { motion } from "framer-motion";



// Properties

interface BackgroundProps {
    top: string;
    bottom: string;
};



// Components

function Background({ top, bottom }: BackgroundProps) {
    const transition = {
        duration: 0.6,
        ease: [0, 0.71, 0.2, 1.01]
    };

    return (
        <div className="visual-background col-flex">
            <motion.h1
                initial={{ transform: `translateX(-200%)` }}
                animate={{ transform: `translateX(0)`}}
                transition={ transition }
            >
                { top }
            </motion.h1>
            <motion.h1
                initial={{ transform: `translateX(200%)` }}
                animate={{ transform: `translateX(0)`}}
                transition={ transition }
            >
                { bottom }
            </motion.h1>
        </div>
    );
}



// Exports

export default Background;