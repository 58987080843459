const SkillsList = [
    {
        title: "HTML",
        category: "Frontend",
        color: ["#e34c26", "#f06529"],
        content: <ul>
            <li>시멘틱 태그를 활용하여 효율적인 레이아웃을 설계할 수 있습니다.</li>
            <li>W3C HTML5 표준을 지키고 웹 접근성을 고려하여 레이아웃을 설계할 수 있습니다.</li>
            <li>다양한 브라우저를 고려한 HTML 레이아웃을 설계할 수 있습니다.</li>
        </ul>,
        icon: process.env.PUBLIC_URL + "/images/skills/html.png"
    },
    {
        title: "CSS",
        category: "Frontend",
        color: ["#264de4", "#2965f1"],
        content: <ul>
            <li>Flex, Grid 레이아웃 기법을 활용할 수 있습니다.</li>
            <li>W3C CSS 표준을 지켜 스타일링을 적용할 수 있습니다.</li>
            <li><code>@keyframes</code>을 사용한 애니메이션과 <code>@media</code>를 사용한 반응형 스타일링을 적용할 수 있습니다.</li>
        </ul>,
        icon: process.env.PUBLIC_URL + "/images/skills/css.png"
    },
    {
        title: "JavaScript",
        category: "Frontend",
        color: ["#ffd700", "#ffff00"],
        content: <ul>
            <li>원시 타입(Primitive Type), 참조 타입(Reference Type)을 구분할 줄 알고 코드에 능숙히 사용할 수 있습니다.</li>
            <li>함수, 객체, 객체 메서드를 코드에 적용할 수 있습니다.</li>
            <li><code>class</code> 문법과 함수 Prototype의 개념을 알고 있고 사용할 수 있습니다.</li>
            <li>DOM 조작이나 Node.js 환경에서의 코딩, AJAX 기법 코딩도 능숙히 할 수 있습니다.</li>
        </ul>,
        icon: process.env.PUBLIC_URL + "/images/skills/js.png"
    },
    {
        title: "jQuery",
        category: "Frontend",
        color: ["#0769ad", "#78cff5"],
        content: <ul>
            <li>jQuery를 사용하여 능숙히 DOM을 다룰 수 있습니다.</li>
            <li>jQuery를 통한 AJAX 기법을 사용할 수 있습니다.</li>
            <li>jQuery UI의 기능이나, <code>animate</code>를 사용하여 시각적 효과를 표현할 수 있습니다.</li>
        </ul>,
        icon: process.env.PUBLIC_URL + "/images/skills/jquery.png"
    },
    {
        title: "Sass",
        category: "Frontend",
        color: ["#cc6699", "#dd88aa"],
        content: <ul>
            <li>SCSS 전용 문법을 활용하여 보기 쉬운 스타일 코드를 작성할 수 있습니다.</li>
            <li>모듈화 기법에 대해 알고 있으며, 실제 SCSS 코딩에 적용할 수 있습니다.</li>
        </ul>,
        icon: process.env.PUBLIC_URL + "/images/skills/sass.png"
    },
    {
        title: "React",
        category: "Frontend",
        color: ["#1c2c4c", "#88dded"],
        content: <ul>
            <li>React 프로젝트의 디렉토리 구조에 대해 알고 있습니다.</li>
            <li>컴포넌트를 세분화하거나, 범용적인 컴포넌트를 설계할 수 있습니다.</li>
            <li>여러 Hook들을 잘 알고 있어 바로 코드에 활용할 수 있습니다.</li>
        </ul>,
        icon: process.env.PUBLIC_URL + "/images/skills/react.png"
    },
    {
        title: "Typescript",
        category: "Frontend",
        color: ["#007acc", "#ffffff"],
        content: <ul>
            <li><code>class</code>, <code>type</code>, <code>interface</code>를 사용할 수 있습니다.</li>
            <li>확실한 타입을 명시하여 높은 가독성의 코드를 작성할 수 있습니다.</li>
            <li><code>null</code>이나 <code>undefined</code>로 인한 예외 처리를 꼼꼼히 하여 사용자 경험에서의 오류를 줄일 수 있습니다.</li>
        </ul>,
        icon: process.env.PUBLIC_URL + "/images/skills/ts.png"
    },
    {
        title: "PHP",
        category: "Backend",
        color: ["#474a8a", "#787cb5"],
        content: <ul>
            <li>PHP를 통한 라우팅 기능을 구현할 수 있습니다.</li>
            <li>CRUD를 모두 갖춘 웹페이지를 구현할 수 있습니다.</li>
            <li>GD 라이브러리를 사용하여 동적 이미지 생성 작업을 수행할 수 있습니다.</li>
            <li>데이터베이스나 파일 및 디렉토리와 연계하여 데이터를 저장하거나 불러올 수 있습니다.</li>
        </ul>,
        icon: process.env.PUBLIC_URL + "/images/skills/php.png"
    },
    {
        title: "Node.js",
        category: "Backend",
        color: ["#68a063", "#303030"],
        content: <ul>
            <li>외부 라이브러리, 그리고 외부 라이브러리를 위한 버전 관리 모듈을 사용할 수 있습니다.</li>
            <li>CRUD를 모두 갖춘 웹페이지를 구현할 수 있습니다.</li>
            <li>데이터베이스나 파일 및 디렉토리와 연계하여 데이터를 저장하거나 불러올 수 있습니다.</li>
            <li>스탠드얼론(Standalone) Javascript 프로그램을 제작할 수 있습니다.</li>
        </ul>,
        icon: process.env.PUBLIC_URL + "/images/skills/node.png"
    },
    {
        title: "Java",
        category: "Backend",
        color: ["#5382a1", "#f89820"],
        content: <ul>
            <li>모듈 방식의 프로그램을 제작하거나, 재사용 가능한 코드를 작성하여 효율성을 높일 수 있습니다.</li>
            <li>원시 자료형과 참조 자료형(객체)의 개념과 차이를 알고 있고, <code>List</code>나 <code>Map</code> 등의 자료구조도 코드에 적용할 수 있습니다.</li>
            <li>클래스 상속, 인터페이스, 추상 클래스, 일반화의 개념을 알고 있고, 사용할 수 있습니다.</li>
        </ul>,
        icon: process.env.PUBLIC_URL + "/images/skills/java.png"
    },
    {
        title: "Kotlin",
        category: "Backend",
        color: ["#b125ea", "#7f52ff"],
        content: <ul>
            <li>Java 코드를 Kotlin 코드로 변형하고 적용할 수 있습니다.</li>
            <li>코루틴 기법을 통해 비동기 형식의 코드를 작성할 수 있습니다.</li>
            <li>웹 서버, 안드로이드 애플리케이션 등 여러 플랫폼에 적용할 수 있습니다.</li>
        </ul>,
        icon: process.env.PUBLIC_URL + "/images/skills/kotlin.png"
    },
    {
        title: "MySQL",
        category: "Data",
        color: ["#00758f", "#f29111"],
        content: <ul>
            <li>SQL의 DML(데이터 조작어)를 사용하여 능숙히 데이터를 삽입, 조회, 수정, 삭제할 수 있습니다.</li>
            <li>SQL의 DDL(데이터 정의어)를 사용하여 능숙히 데이터 구조(테이블 등)를 삽입, 조회, 수정, 삭제할 수 있습니다.</li>
            <li>데이터베이스 관계(Relationship)에 대해 알고 있으며, 실 구조에 개념을 적용할 수 있습니다.</li>
            <li>타 언어나 플랫폼과 연계하여 웹 환경이나 여러 프로그램에 사용할 수 있습니다.</li>
        </ul>,
        icon: process.env.PUBLIC_URL + "/images/skills/mysql.png"
    }
];


export default SkillsList;