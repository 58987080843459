import PortfolioItem from "../PortfolioItem";
import MainSlideItem from "./SlideItem";


// Interfaces
interface MainSlideProps {
    slideIndex: number;
    list: PortfolioItem[];
};


// Components
function MainSlide({slideIndex, list}: MainSlideProps) {
    return (
        <div className="flex aic jcfe portfolio-main-slide">
            {
                list.map((item, i) => (
                    <MainSlideItem key={i} active={i === slideIndex} item={item}></MainSlideItem>
                ))
            }
        </div>
    );
}


// Export
export default MainSlide;


