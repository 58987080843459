const titleVariants = {
    "englishTitleHide": {
        transform: "translateY(-10em) rotate(90deg)",
        opacity: 0,
        boxShadow: ".2em .2em 2em #fff0",
        background: "tomato"
    },
    "englishTitleShow": {
        transform: "rotate(90deg)",
        opacity: 1,
        boxShadow: "0 0 15px #fffa",
        background: "black"
    },
    "dummyTitleHide": {
        width: 0,
        left: "-100%"
    },
    "dummyTitleShow": {
        left: 0,
        width: "100%"
    },


    "hidden": {
        opacity: 0,
        transform: "translateY(-45px)"
    },
    "visible": {
        opacity: 1,
        transform: "translateY(0px)"
    }
};


const titleWrapVariants = {
    "hidden": {
    },
    "visible": {
        transition: {
            when: "beforeChildren",
            staggerChildren: .15,
            delayChildren: 1
        }
    }
};


export {
    titleVariants, titleWrapVariants
};