import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ContactItem from "./ContactItem";
import { motion } from "framer-motion";


// Properties
interface ContactCardProp {
    contact: ContactItem
}


// Components
function ContactCard({ contact }: ContactCardProp) {
    const hrefClickHanlder = () => {
        const { href } = contact;
        if (href !== undefined) {
            window.location.href = href;
        }
    }

    return (
        <motion.div 
            className="contact-card" 
            style={{
                background: contact.backgroundColor,
                color:      contact.foregroundColor,
                ...contact.beforeStyle
            }} 
            onClick={hrefClickHanlder}
            variants={{
                visible: {
                    ...contact.afterStyle,
                    opacity: 1
                }
            }}>
            <div className="col-flex">
                <FontAwesomeIcon icon={ contact.icon }></FontAwesomeIcon>
                <div className="contact-card-value">
                    { contact.value }
                </div>
            </div>
        </motion.div>
    )
};


// Exports
export default ContactCard;