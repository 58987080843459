import "./assets/css/style.scss";
import About from "./components/About/About";
import Contact from "./components/Contact/Contact";
import Portfolio from "./components/Portfolio/Portfolio";
import Skillstack from "./components/Skillstack/Skillstack";
import Visual from "./components/Visual/Visual";
import { useRef } from "react";




function Main() {
    const ref = useRef<HTMLDivElement>(null);

    return (
        <div id="scroll-snap" ref={ ref }>
            <Visual />
            <About />
            <Skillstack />
            <Portfolio />
            <Contact />
        </div>
    )
}


export default Main;