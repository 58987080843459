import { motion } from "framer-motion";


// Properties

interface LineProp {
    content: string;
    className: string;
}



// Components

function Line({ content, className }: LineProp) {
    const transition = { duration: .6, ease: "easeInOut" };

    return (
        <motion.div 
            className={ ["visual-line flex aic jcsb", className].join(" ") }
            initial={{ opacity: 1 }}
            animate={{ opacity: 0.1 }}
            transition={ transition }
        >
            <motion.hr
                initial={{ width: 0 }}
                animate={{ width: "100%" }}
                transition={ transition } />
            <p>{ content }</p>
        </motion.div>
    )
}


export default Line;